<template>
    <van-tabbar v-model="index" active-color="red">
        <van-tabbar-item icon="home-o" to="/">
            首页
        </van-tabbar-item>
<!--        <van-tabbar-item icon="comment-o" to="/message" :badge="tabNumObj.noReadMsgNum <= 0 ? '' : tabNumObj.noReadMsgNum >= 99 ? '99+' : tabNumObj.noReadMsgNum">-->
<!--            消息-->
<!--        </van-tabbar-item>-->
        <!-- <van-tabbar-item icon="shop-o" to="/shopList">
            供应商
        </van-tabbar-item> -->
		<van-tabbar-item icon="flag-o" to="/apply">
		    申请入驻
		</van-tabbar-item>
        <van-tabbar-item  to="/goodsCategory">
            <template #icon>
                <i class="fa fa-th-large"></i>    
            </template>
            
            全部商品
        </van-tabbar-item>
        <van-tabbar-item icon="shopping-cart-o" to="/shoppingCart" :badge="tabNumObj.shoppingCartNum <= 0 ? '' : tabNumObj.shoppingCartNum >= 99 ? '99+' : tabNumObj.shoppingCartNum">
            购物车
        </van-tabbar-item>
        <van-tabbar-item icon="user-circle-o" to="/mine">
            我的
        </van-tabbar-item>
    </van-tabbar>
</template>
<script type="text/javascript">
import request from "@/utils/request";
export default {
    name: "bottomTabNav",
    props: ["index"],
    data() {
        return {
            tabNumObj:{},
        }
    },
    created() {
        this.getList();
    },
    methods:{
        getList(){
            request.post("/api/index/queryBottomTabNum").then(data => {
                this.tabNumObj = data;
                this.storage.set("noReadMsgNum",data.noReadMsgNum);
            });
        }
    }
}
</script>
<style type="text/css">
    
</style>