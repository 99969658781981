<template>
	<div class="">
		<i class="van-icon van-icon-arrow-left" style="position: absolute; top: 18px; left: 5px; z-index: 10; font-size: 16px" @click="$router.go(-1)" v-show="!inShop"></i>
		<van-search style="padding-left: 25px" v-model="keyword" @focus="onKeyswordFocus()" shape="round" placeholder="" show-action v-show="!inShop">
			<template #action>
				<div @click="onSearch">搜索</div>
			</template>
		</van-search>
		<div style="position: relative" v-show="false">
			<van-dropdown-menu style="width: 50%" class="leftSortMenu">
				<van-dropdown-item v-model="defaultSort" :options="sortList" @change="updateSort" @click.capture="switchToDefaultSort" />
			</van-dropdown-menu>
			<div class="saleNumSort" :style="{ color: sort == 'saleNum' ? 'red' : 'black' }" @click="updateSort('saleNum')">销量排序</div>
		</div>
		<div :style="containerCss" style="display: flex">
			<div style="width: 90px; flex-shrink: 0; display: flex; flex-direction: column; overflow-y: auto;overflow-x: hidden;">
				<template v-for="(item, index) in categoryList">
					<div class="firstLevelItem" :class="{ selected: activeCate1 == index }" @click="onClickLevel1Item(item, index)">
						<span class="cateName" v-text="item.name"></span>
						<span v-if="cateGoodsInfo[item.id]" class="firstLevelItemTag">{{ cateGoodsInfo[item.id] }}</span>
					</div>
				</template>
				<div>
					<div style="height: 60px;width: 100px;">
						&nbsp;&nbsp; 
					</div>
					
				</div>
			</div>
			<div style="flex-grow: 1; overflow: auto; background-color: #fff">
				<div :style="containerCss" style="overflow: auto; margin-top: 0; margin-left: 5px">
					<div v-if="categoryList.length > 0 && activeCate1Data.childList" style="overflow: auto">
						<div style="display: flex; flex-direction: row; flex-wrap: wrap">
							<div class="secondLevelItem" :class="{ selected: cate2Id == '' }" @click="onClickLevel2Item({}, -1)">
								<div class="secondLevelImgDiv">
									<img :src="getImgUrl(activeCate1Data.img)" />
								</div>
								<div class="secondLevelItemText">全部商品</div>
							</div>
							<template v-for="(child, childIndex) in activeCate1Data.childList">
								<div class="secondLevelItem" :class="{ selected: cate2Id == child.id }" @click="onClickLevel2Item(child, childIndex)">
									<div class="secondLevelImgDiv">
										<img :src="getImgUrl(child.img)" />
									</div>
									<div class="secondLevelItemText van-multi-ellipsis--l2">{{ child.name }}</div>
								</div>
							</template>
							
						</div>
					</div>
					<van-list v-model="loading" :finished="finished" finished-text="没有更多了" :offset="100" @load="refresh">
						<template #default>
							<div
								v-for="goods in goodsList"
								@click="navigateTo('/goodsInfo?goodsId=' + goods.goodsId + '&instanceId=' + goods.instanceId)"
								style="display: flex; align-items: center; margin: 12px 0; min-height: 120px"
							>
								<div
									style="
										position: relative;
										background-color: #f7f7f7;
										border-radius: 4px;
										width: 90px;
										height: 120px;
										display: flex;
										align-items: center;
										justify-content: center;
									"
								>
									<img :src="getImgUrl(goods.goodsLogo)" style="width: 70px; height: 70px; border-radius: 4px" />
								</div>
								<div style="text-align: left; color: #333; width: calc(100% - 110px); margin-top: 0; margin-left: 10px; font-size: 13px">
									<div class="van-multi-ellipsis--l2">{{ goods.goodsRemark }}</div>
									<div class="van-multi-ellipsis--l2" style="font-size: 13px; color: #999; margin: 4px 0">
										{{ getInstanceDesc(goods, true) }}
									</div>
									<div class="flex-between" style="font-size: 12px; color: #999; margin: 4px 0 8px">
										<!-- <span style="position: relative;top:-2px;">{{goods.viewNum}}人浏览</span> -->
										<!-- <span style="position: relative;text-decoration: line-through;">¥ {{qy.formatMoney(goods.goodsPrice)}}</span> -->
										<span style="font-size: 12px; color: red">
											<span style="font-size: 12px; color: red">¥</span>
											{{ qy.formatMoney(goods.wholesalePrice, '面议') }}
										</span>
										<span style="padding-right: 6px">库存：{{ goods.stockNum }}</span>
									</div>
									<div class="flex-between" @click.stop="" style="font-size: 12px; color: #999; margin: 5px 0; justify-content: flex-start">
										<!-- <span style="position: relative;top:-2px;">{{goods.viewNum}}人浏览</span> -->

										<div class="flex-between" style="color: #333">
											<div style="padding: 5px; background-color: #f2f3f5"><van-icon name="minus" @click.stop="addCartNum(goods, -1)" /></div>
											<span style="padding: 5px; min-width: 20px; text-align: center; margin: 0 2px; background-color: #f2f3f5">{{ goods.cartNum }}</span>
											<div style="padding: 5px; background-color: #f2f3f5"><van-icon name="plus" @click.stop="addCartNum(goods, 1)" /></div>
										</div>
									</div>
									<div style="font-size: 12px; color: #888; margin-top: 5px; display: flex; justify-content: flex-start">
										<span @click.stop="navigateTo('/shop?id=' + goods.shopId)">
											{{ goods.shopName }}
											<span style="font-size: 14px">></span>
										</span>
									</div>
								</div>
							</div>
						</template>
					</van-list>
				</div>
				<div style="position: absolute;;left:0px;bottom: 10px;z-index:100;background-color: #f7f7f7;width: 70px;padding-left: 20px;">
					<van-badge :content="cartTotalNum" max="99" >
						<van-icon name="cart-o" size="32" @click="navigateTo('/shoppingCart')"/>
					</van-badge>
				</div>
			</div>
		</div>

		<!-- <van-overlay :show="true"  >
            <van-loading type="spinner" />
        </van-overlay> -->
	</div>
</template>
<script type="text/javascript">
// @ is an alias to /src
import bottomTabNav from '@/components/bottomTabNav';
import request from '@/utils/request';
import { Toast } from 'vant';
export default {
	name: 'goodsList',
	props: ['shopId'],
	components: { bottomTabNav },
	data() {
		return {
			keyword: '',
			active: 0,
			defaultSort: '',
			sort: '',
			loading: false,
			finished: false,
			pageNum: 1,
			pageSize: 10,
			isFirstInput: true,
			containerHeight: 0,
			factoryName: '销量',
			isRequesting: false,
			factoryList: [{ text: '销量', value: '销量' }],
			sort: '',
			sortList: [
				{ text: '综合排序', value: '' },
				{ text: '价格最低', value: 'minPrice' },
				{ text: '价格最高', value: 'maxPrice' }
			],
			proxyId: 0,
			cateId: '',
			goodsList: [],
			goodsLogoSize: 0,
			categoryList: [],
			activeCate1: 0,
			cateGoodsInfo: {},
			cate1Id: '',
			cate2Id: ''
		};
	},
	computed: {
		activeCate1Data() {
			return this.categoryList && this.categoryList[this.activeCate1];
		},
		inShop() {
			return !!this.shopId;
		},
		containerCss() {
			let css = {};
			// css[this.inShop?'minHeight':'height']=this.containerHeight;
			css['height'] = this.containerHeight;
			return css;
		},
		cartTotalNum(){
			let totalNum=0;
			for(let k in this.cateGoodsInfo){
				totalNum+=this.cateGoodsInfo[k];
			}
			return totalNum;
		}
	},
	created() {
		this.keyword = this.getUrlParam('keyword') || '';
		//this.refresh();
		this.fetchCateList();
		this.goodsLogoSize = '70px';
		let otherHeight = 70;
		if (this.inShop) {
			otherHeight = 190;
		} else {
			this.hideShare();
		}
		let proxyStg = this.storage.get('proxyStg');
		if (proxyStg) {
			this.proxyId = JSON.parse(proxyStg).id;
		}
		this.containerHeight = document.documentElement.clientHeight - otherHeight + 'px';
		this.fetchCateNumList();
	},

	methods: {
		onUpdateShopingCartSuccess(data) {
			this.fetchCateNumList();
		},
		fetchCateNumList() {
			if (!this.isLogin()) {
				return;
			}
			this.doPost('/api/shopCart/cateNum', { shopId: this.shopId || '' }, (rs) => {
				this.cateGoodsInfo = rs.data;
			});
		},
		fetchCateList() {
			request.post('/api/category/list',{ shopId: this.shopId || '' }).then((rs) => {
				rs.forEach((item) => {
					if (item.banner) {
						item.banner = item.banner.split(',');
					}
				});
				let cateList = rs.sort((o1, o2) => {
					return o1.sortNum - o2.sortNum;
				});
				cateList.splice(0, 0, {
					name: '全部商品'
				});
				this.categoryList = cateList;
			});
		},
		onClickLevel1Item(item, index) {
			this.cate1Id = item.id || '';
			this.cate2Id = '';
			this.activeCate1 = index;
			this.onSearch();
		},
		onClickLevel2Item(item, childIndex) {
			this.cate2Id = item.id || '';
			this.onSearch();
		},
		onSearch() {
			this.reset();
			this.refresh();
		},
		onKeyswordFocus: function () {
			if (this.isFirstInput) {
				this.isFirstInput = false;
				this.keyword = '';
			}
		},
		switchToDefaultSort() {
			this.sort = this.defaultSort;
		},
		updateSort(value) {
			//console.log(value)
			if (this.sort == value) {
				return;
			}
			this.reset();
			this.sort = value;
			this.refresh();
		},
		reset() {
			this.pageNum = 1;
			this.goodsList = [];
			this.finished = false;
		},
		refresh() {
			if (this.isRequesting) {
				return;
			} else {
				this.isRequesting = true;
			}
			request
				.post('/api/goods/list', {
					sort: this.sort,
					cateId: this.cate2Id || this.cate1Id,
					proxyId: this.proxyId,
					keyword: this.keyword,
					shopId: this.shopId || '',
					pageNum: this.pageNum++,
					pageSize: this.pageSize
				})
				.then((rs) => {
					this.loading = false;
					this.isRequesting = false;
					if (rs.rows.length < this.pageSize || rs.total <= (this.pageNum - 1) * this.pageSize) {
						this.finished = true;
					}

					this.goodsList = this.goodsList.concat(rs.rows);
				});
		},
		gotoShop(id) {
			this.$router.push({ path: '/shop', query: { id: id } });
		}
	}
};
</script>
<style type="text/css" scoped="true">
.firstLevelItem {
	padding: 14px 8px 14px 4px;
	font-size: 14px;
	flex-shrink: 0;
	margin: 2px 4px;
	position: relative;
	font-size: 14px;
}
.firstLevelItem.selected {
	background-color: #fff;
	font-weight: bold;
}
.firstLevelItem.selected span.cateName {
	color: #ee0a24;
	border-left: 2px solid #ee0a24;
	padding-left: 3px;
}
.firstLevelItem .firstLevelItemTag {
	position: absolute;
	right: 1px;
	top: 4px;
	background-color: #fb4e48;
	padding: 1px 4px;
	border-radius: 20px;
	/* min-width: 4px; */
	color: #fff;
	font-size: 12px;
}
.secondLevelItem {
	padding: 4px 6px;
	font-size: 14px;
	flex-shrink: 0;
	margin: 2px 0;
	width: 60px;
	
}
.secondLevelItem.selected .secondLevelItemText {
	color: #fff;
	background-color: #ee0a24;
	border-radius: 12px;
}
.secondLevelImgDiv {
	display: flex;
	justify-content: center;
}
.secondLevelImgDiv img {
	width: 50px;
	height: 50px;
}
.secondLevelItemText {
	font-size: 12px;
	color: #333;
	padding: 2px 4px;
	position: relative;
	top: 2px;
	text-align: center;
	padding: 4px 4px;
	text-align: center;
}
</style>
